import each from 'lodash/each';
import * as httpConstants from '../httpConstants';
import HttpRequest from './HttpRequest';

class HttpRequestBuilder {
  /**
   * @type HttpRequest
   */
  request;

  /**
   *
   * @param method
   * @param path
   */
  constructor(method, path) {
    this.request = new HttpRequest(path, method);
  }

  static delete(path) {
    return new HttpRequestBuilder(httpConstants.HTTP_METHOD_DELETE, path);
  }

  static get(path) {
    return new HttpRequestBuilder(httpConstants.HTTP_METHOD_GET, path);
  }

  static post(path) {
    return new HttpRequestBuilder(httpConstants.HTTP_METHOD_POST, path);
  }

  static put(path) {
    return new HttpRequestBuilder(httpConstants.HTTP_METHOD_PUT, path);
  }

  /**
   * @param {string} path Request path including possible path variable placeholders
   * @param {Object} pathVariables Path variables
   * @returns {string} Parsed path
   */
  static buildPath(path, pathVariables) {
    each(pathVariables, (value, name) => {
      path = path.replace(`:${name}`, value);
      path = path.replace(`{${name}}`, value);
    });

    return path;
  }

  /**
   * @param {*} body Any payload that can be serialized
   * @returns {HttpRequestBuilder}
   */
  body(body) {
    this.request.setBody(body);

    return this;
  }

  /**
   * @param {string} dispatchType
   * @param {function} callback
   * @returns {HttpRequestBuilder}
   */
  dispatch(dispatchType, callback) {
    this.request.addDispatch(dispatchType, callback);

    return this;
  }

  /**
   * @param {function} callback
   * @returns {HttpRequestBuilder}
   */
  onInit(callback) {
    return this.dispatch(httpConstants.REQUEST_INIT, callback);
  }

  /**
   * @param {function} callback
   * @returns {HttpRequestBuilder}
   */
  onError(callback) {
    return this.dispatch(httpConstants.REQUEST_ERROR, callback);
  }

  /**
   * @param {function} callback
   * @returns {HttpRequestBuilder}
   */
  onFailure(callback) {
    return this.dispatch(httpConstants.REQUEST_FAILURE, callback);
  }

  /**
   * @param {function} callback
   * @returns {HttpRequestBuilder}
   */
  onNotFound(callback) {
    return this.dispatch(httpConstants.REQUEST_NOTFOUND, callback);
  }

  /**
   * @param {function} callback
   * @returns {HttpRequestBuilder}
   */
  onUnauthorized(callback) {
    return this.dispatch(httpConstants.REQUEST_UNAUTHORIZED, callback);
  }

  /**
   * @param {function }callback
   * @returns {HttpRequestBuilder}
   */
  onSuccess(callback) {
    return this.dispatch(httpConstants.REQUEST_SUCCESS, callback);
  }

  build() {
    const url = this.buildUrl(this.request);

    this.request.setUrl(url);

    return this.request;
  }

  /**
   * @param {HttpRequest} request
   * @returns {string} Final request URL
   * @private
   */
  buildUrl(request) {
    return (
      HttpRequestBuilder.buildPath(
        request.getPath(),
        request.getPathVariables()
      ) + this.buildQueryString(request.getQueryParameters())
    );
  }

  /**
   * @param {Object} parameters
   * @returns {string}
   */
  buildQueryString = parameters => {
    const queryStrings = [];

    each(parameters, (value, name) => {
      if (value) {
        queryStrings.push(
          `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
        );
      }
    });

    if (queryStrings.length === 0) {
      return '';
    }

    return `?${queryStrings.join('&')}`;
  };

  /**
   * @param {string} name
   * @param {string|any} value
   * @returns {HttpRequestBuilder}
   */
  pathParam(name, value) {
    this.request.setPathVariable(name, value);

    return this;
  }

  /**
   * @param {string} name
   * @param {string|any} value
   * @returns {HttpRequestBuilder}
   */
  queryParam(name, value) {
    this.request.setQueryParameter(name, value);

    return this;
  }

  /**
   * @param {string} name  Header name
   * @param {string} value Header value
   * @returns {HttpRequestBuilder}
   */
  setHeader(name, value) {
    this.request.headers[name] = value;

    return this;
  }
}

export default HttpRequestBuilder;
