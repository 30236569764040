<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import {
  inputToTenant,
  supportedLocales,
  supportedTenants
} from '@/config/countriesAndLocales.js';

function isTenantValid(newTenantId) {
  return newTenantId && supportedTenants.includes(newTenantId);
}
function isLanguageValid(newLanguage) {
  return newLanguage && supportedLocales.includes(newLanguage);
}

const routeGuard = (to, from) => {
  const tenantId = inputToTenant(to.params.countryOrTenantId);
  if (!isTenantValid(tenantId)) return false;

  const locale = to.params.locale;
  if (!isLanguageValid(locale)) {
    const lang = locale.substring(0, 2);
    if (locale.length > 3 && isLanguageValid(lang)) {
      return { ...to, params: { ...to.params, locale: lang } };
    }
    return false;
  }
};

export default {
  name: 'LocaleContainer',
  beforeRouteEnter(to, from) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    return routeGuard(to, from);
  },
  beforeRouteUpdate(to, from) {
    return routeGuard(to, from);
  },
  props: {
    countryOrTenantId: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      required: true
    }
  },
  watch: {
    locale: {
      immediate: true,
      async handler(newLocale) {
        this.$localePlugin.updateLocale(newLocale);
      }
    },
    countryOrTenantId: {
      immediate: true,
      handler(input) {
        this.$rttI18n.setTenantId(inputToTenant(input));
      }
    }
  }
};
</script>

<style scoped></style>
