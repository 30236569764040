import HttpResponse from './httpResponse/HttpResponse';
import * as httpConstants from './httpConstants';

class HttpService {
  /**
   * @type Object
   */
  authHeaders;

  /**
   * @param {Map} store Vuex' store
   */
  setStore(store) {
    this.store = store;
  }

  /**
   * @param {HttpRequest} request Prepared Http request
   * @returns {Promise<void>}
   */
  async executeRequest(request) {
    // dispatch init as we want to know what requests have been issued
    this.dispatchInit(request);

    try {
      const fetchConfig = this.prepareFetchData(request);

      let url = request.getUrl();

      const fetchResponse = await fetch(url, fetchConfig);
      await this.handleResponse(fetchResponse, request);
    } catch (error) {
      this.customHandleApiError(
        request,
        error,
        httpConstants.HTTP_STATUS_UNREACHABLE
      );
    }
  }

  prepareFetchData = request => {
    const requestConfig = {
      headers: request.getHeaders(),
      method: request.getMethod()
    };

    let body = request.getBody();

    if (body !== null) {
      requestConfig.body = JSON.stringify(body);
    }

    return requestConfig;
  };

  /**
   * @param {String} action to be executed
   * @param {Object} payload to be dispatched
   */
  dispatch = (action, payload) => {
    this.store.dispatch(action, payload);
  };

  dispatchInit = request => {
    this.handleInjectedDispatches(httpConstants.REQUEST_INIT, request);
  };

  dispatchSuccess = (request, response) => {
    this.handleInjectedDispatches(
      httpConstants.REQUEST_SUCCESS,
      request,
      response
    );
  };

  dispatchError = (request, error) => {
    this.handleInjectedDispatches(
      httpConstants.REQUEST_FAILURE,
      request,
      error
    );
  };

  /**
   * @param {string} type Dispatch type
   * @param {HttpRequest} request Issuing request
   * @param {HttpResponse|null} response Possible response
   */
  handleInjectedDispatches(type, request, response) {
    request.getDispatches(type).forEach(callback => {
      // Vuex store dispatch and commit methods passed to callback to interact with Vuex state/store
      callback(request, response);
    });
  }

  /**
   * @param {Response} fetchResponse WebAPI response
   * @param {HttpRequest} request Response triggered request
   */
  handleResponse = async (fetchResponse, request) => {
    try {
      const response = new HttpResponse(fetchResponse.status);
      const clonedFetchResponse = fetchResponse.clone();

      // extract headers
      fetchResponse.headers.forEach((value, name) => {
        response.setHeader(name, value);
      });

      const responseBody = await fetchResponse.text();
      const contentType = response.getHeader('Content-Type');

      // set body only if present
      if (responseBody && contentType && contentType.indexOf('json') > -1) {
        const responseJson = await clonedFetchResponse.json();
        response.setBody(responseJson);
        this.processResponse(request, response);
      } else if (
        responseBody &&
        contentType &&
        contentType.indexOf('text/plain') > -1
      ) {
        response.setBody(responseBody);
        this.processResponse(request, response);
      } else if (
        responseBody &&
        contentType &&
        contentType.indexOf('application/vnd.ms-excel') > -1
      ) {
        response.setBody(
          new Blob([await clonedFetchResponse.blob()], { type: contentType })
        );
        this.processResponse(request, response);
      } else {
        this.processResponse(request, response);
      }
    } catch (error) {
      this.dispatchError(request, error);
    }
  };

  /**
   * @param {HttpResponse} response
   * @returns {boolean}
   */
  isResponseSuccessful = response => {
    const statusCode = response.getStatusCode();

    return statusCode >= 200 && statusCode < 300;
  };

  /**
   * Generic point being called for any erroneous API response
   *
   * @param {HttpRequest}request
   * @param {TypeError} error
   * @param {number} status
   * @param {string} type defaults to REQUEST_ERROR
   */
  handleApiError = (
    request,
    error,
    status,
    type = httpConstants.REQUEST_ERROR
  ) => {
    // let the application know that a hard API error occurred
    this.dispatch(type, {
      error: error.toString(),
      status,
      request
    });
  };

  /**
   * Custom error handling being called for erroneous API response
   *
   * @param {HttpRequest} request
   * @param {TypeError} error
   * @param {number} status
   * @param {string} type defaults to REQUEST_ERROR
   */
  customHandleApiError = (
    request,
    error,
    status,
    type = httpConstants.REQUEST_ERROR
  ) => {
    // if custom error handler is pushed to dispatches array dispatch it
    if (request.getDispatches(type) && request.getDispatches(type).length > 0) {
      this.handleInjectedDispatches(type, request, error);
    } else {
      this.handleApiError(request, error, status, type);
    }
  };

  /**
   * @param {HttpRequest} request
   * @param {HttpResponse} response
   */
  processResponse = (request, response) => {
    const { REQUEST_NOTFOUND, REQUEST_FAILURE, REQUEST_UNAUTHORIZED } =
      httpConstants;

    const status = response.getStatusCode();

    // successful
    if (this.isResponseSuccessful(response)) {
      this.dispatchSuccess(request, response);
      return;
    }

    switch (status) {
      // treat 401 & 403 as auth Error
      case 401:
      case 403:
        this.customHandleApiError(
          request,
          !response || !response.body || Object.keys(response.body) === 0
            ? 'Unauthorized'
            : response,
          status,
          REQUEST_UNAUTHORIZED
        );
        break;

      // treat 404 as Error too
      case 404:
        this.customHandleApiError(
          request,
          !response || !response.body || Object.keys(response.body) === 0
            ? 'Not Found'
            : response,
          status,
          REQUEST_NOTFOUND
        );
        break;

      // treat 500 as Error too
      case 500:
        this.customHandleApiError(
          request,
          response.body,
          status,
          REQUEST_FAILURE
        );
        break;

      default:
        this.customHandleApiError(request, response, status, REQUEST_FAILURE);
        break;
    }
  };
}

export default HttpService;
