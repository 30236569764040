<template>
  <v-card :class="cls" v-if="wrap">
    <slot />
  </v-card>
  <div :class="cls" v-else>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ConditionalCard',
  props: {
    wrap: {
      type: Boolean,
      required: true
    },
    cls: {
      type: String
    }
  }
};
</script>
