export function showTrustPilotLink(trackingData) {
  return (
    !!trackingData?.trustpilotInvitationLink &&
    trackingData?.toAddress?.postcode &&
    trackingData?.parcelNumber &&
    Number(
      trackingData?.parcelNumber[trackingData?.parcelNumber.length - 1]
    ) !== 5
  );
}
