import {
  defaultThemeName,
  getThemeIdFromRoute,
  getThemeIdFromStateAndRoute
} from '@/config/themes.js';
import { setupVuex } from '@/store/index.js';
import { setupRouter } from '@/router/index.js';
import { getVuetifyInstance } from '@/plugins/vuetify.js';
import { createHead } from '@unhead/vue';
import createRttI18n, { getStartLocale } from '@/plugins/rttI18nPlugin.js';
import { inputToTenant } from '@/config/countriesAndLocales.js';
import mittPlugin from '@/plugins/mittPlugin.js';
import { sendAnalytics } from '@/services/analytics.js';
import createRttMoment from '@/plugins/rttMomentPlugin.js';
import localePlugin from '@/plugins/localePlugin.js';
import { validateLocale } from '@/services/localeValidation.js';
import oberserverPlugin from '@/plugins/observerPlugin.js';
import defaultConfig from '@/config/defaultConfig';
import { getUtmSource } from '@/router/queryParam.js';

export async function init(urlPath, app, store, isWidget) {
  app.config.productionTip = false;
  app.use(createHead());

  setupVuex(app, store);

  const router = setupRouter(isWidget);
  const route = router.resolve(urlPath);
  app.use(router);
  if (isWidget) await router.replace(route);
  const { params } = route;

  const tenantId = inputToTenant(params.countryOrTenantId);
  const startLocale = getStartLocale(params.locale);
  try {
    validateLocale(startLocale);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
  }
  const rttI18n = createRttI18n({
    locale: startLocale,
    tenantId,
    isWidget
  });
  app.use(rttI18n);
  const rttMoment = createRttMoment({ locale: startLocale });
  app.use(rttMoment);
  app.use(localePlugin);
  app.use(oberserverPlugin);
  app.use(mittPlugin);

  const setTheme = themeId =>
    store.replaceState({
      ...store.state,
      themeId: themeId || defaultThemeName()
    });

  const themeIdFromRoute = getThemeIdFromRoute(route);
  const hasGdprConsent =
    defaultConfig.widgetConfiguration.gdprApproved ||
    store.state.gdprConsent[tenantId];
  if (!params.trackingId || themeIdFromRoute) {
    app.use(getVuetifyInstance(themeIdFromRoute));
    setTheme(themeIdFromRoute);
    app.mount(`#${store.$containerId}`);
  } else {
    await store.dispatch(
      params.postcode && hasGdprConsent
        ? 'loadAdditionalTrackingData'
        : 'loadTrackingDataById',
      { ...params, $route: route, tenantId, utmSource: getUtmSource(route) }
    );

    const themeId = getThemeIdFromStateAndRoute(store.state, route);
    app.use(getVuetifyInstance(themeId));
    setTheme(themeId);
    app.mount(`#${store.$containerId}`);
  }

  window.addEventListener('error', errorEvent => {
    if (!store.state.trackingData.tenantId) return;
    sendAnalytics(
      {
        trackingId: '',
        parcelNumber: '',
        ...store.state.trackingData
      },
      'global',
      'error',
      {
        message: errorEvent.message,
        position: `${errorEvent.filename}:${errorEvent.lineno}:${errorEvent.colno}`,
        userAgent: navigator.userAgent || navigator.vendor || window.opera
      }
    );
  });
}
