import '@/styles/main.scss';
import '@/styles/fonts.scss';
import '@/styles/overrides.scss';
import { defaultThemeName } from '@/config/themes';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi-svg.mjs';
import { mapValues } from '@/services/object.js';

const themeColors = {
  bettermile: {
    'primary-fade': '#ffffff88',
    accent: '#0071ff',
    anchor: '#061ab1',
    background: '#f9f9f9',
    custom: '#ff0000',
    error: '#d32f2f', // $red.darken-2
    footerBackground: '#000',
    footerColor: '#fff',
    footerGlsIconColor: '#fff',
    headerBackground: '#fff',
    headerColor: '#000',
    info: '#0222F5', // $blue.darken-2
    primary: '#090909',
    secondary: '#ffffff', //white
    stopsContainer: '#090909',
    success: '#388e3c', // $green.darken-2,
    warning: '#ffa000' // $amber.darken-2
  },
  bvb: {
    'primary-fade': '#FFD900', //bvb yell88',
    accent: '#000000',
    anchor: '#5a5217',
    background: '#f9f9f9',
    error: '#ff0000', // $red.darken-2
    footerBackground: '#000',
    footerColor: '#fff',
    footerGlsIconColor: '#fff',
    headerBackground: '#fff',
    headerColor: '#000',
    info: '#000000', // $blue.darken-2
    primary: '#FFD900', //bvb yellow
    secondary: '#ffffff', //white
    stopsContainer: '#090909',
    success: '#ffdd00', // $green.darken-2
    warning: '#0F0F0F' // $amber.darken-2
  },
  gls: {
    //'on-primary': '#ffffff', //GLS yell88',
    accent: '#0071ff',
    anchor: '#061ab1',
    background: '#f9f9f9',
    custom: '#ff0000',
    error: '#d32f2f', // $red.darken-2
    footerBackground: '#000',
    footerColor: '#fff',
    footerGlsIconColor: '#fff',
    headerBackground: '#fff',
    headerColor: '#000',
    info: '#061ab1', // $blue.darken-2
    primary: '#ffd100', //GLS yellow
    secondary: '#ffffff', //white
    stopsContainer: '#ffd100',
    success: '#388e3c', // $green.darken-2,
    warning: '#ffa000' // $amber.darken-2
  },
  'gls-it': {
    'primary-fade': '#ffd100', //GLS yell88',
    accent: '#0071ff',
    anchor: '#061ab1',
    background: '#f9f9f9',
    custom: '#ff0000',
    error: '#d32f2f', // $red.darken-2
    footerBackground: '#061ab1',
    footerColor: '#fff',
    footerGlsIconColor: '#fff',
    headerBackground: '#061ab1',
    headerColor: '#fff',
    info: '#061ab1', // $blue.darken-2
    primary: '#ffd100', //GLS yellow
    secondary: '#ffffff', //white
    stopsContainer: '#ffd100',
    success: '#388e3c', // $green.darken-2,
    warning: '#ffa000' // $amber.darken-2
  },
  'gls-one': {
    'primary-fade': '#fcbf00', //GLS yell88',
    accent: '#000000',
    anchor: '#061ab1',
    background: '#f9f9f9',
    error: '#d32f2f', // $red.darken-2
    footerBackground: '#061ab1',
    footerColor: '#fff',
    footerGlsIconColor: '#fff',
    headerBackground: '#061AB1',
    headerColor: '#fff',
    info: '#061ab1', // $blue.darken-2
    primary: '#fcbf00', //GLS yellow
    secondary: '#ffffff', //white
    stopsContainer: '#ffd100',
    success: '#388e3c', // $green.darken-2
    warning: '#ffa000' // $amber.darken-2
  },
  'royalmail-uk': {
    'primary-fade': '#fcbf00', //GLS yell88',
    accent: '#000000',
    anchor: '#061ab1',
    background: '#f9f9f9',
    error: '#d32f2f', // $red.darken-2
    footerBackground: '#fff',
    footerColor: '#000',
    footerGlsIconColor: '#061ab1',
    headerBackground: '#061AB1',
    headerColor: '#000',
    info: '#fff', // $blue.darken-2
    primary: '#d40918',
    secondary: '#ffffff', //white
    stopsContainer: '#da202a',
    success: '#388e3c', // $green.darken-2
    warning: '#ffa000' // $amber.darken-2
  }
};

export function importThemeStylesheet(themeName) {
  const themeOverrides = {
    bettermile: () =>
      import(
        /* webpackChunkName: "bettermile" */ '../styles/css-variables.bettermile.scss'
      ),
    bvb: () =>
      import(/* webpackChunkName: "bvb" */ '../styles/css-variables.bvb.scss'),
    'gls-it': () =>
      import(
        /* webpackChunkName: "gls-it" */ '../styles/css-variables.gls-it.scss'
      ),
    'gls-one': () =>
      import(
        /* webpackChunkName: "gls-one" */ '../styles/css-variables.gls-one.scss'
      ),
    'royalmail-uk': () =>
      import(
        /* webpackChunkName: "royalmail" */ '../styles/css-variables.royalmail-uk.scss'
      )
  };
  const themeScssImport = themeOverrides[themeName];
  if (themeScssImport) {
    themeScssImport();
  }
}

export function setThemeName(vuetify, themeName) {
  vuetify.theme.global.name = themeName;
}

export function getVuetifyInstance(themeName) {
  importThemeStylesheet(themeName);

  return createVuetify({
    theme: {
      defaultTheme: themeName || defaultThemeName(),
      themes: mapValues(themeColors, colors => ({
        dark: false,
        colors,
        variables: {}
      }))
    },
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi
      }
    }
  });
}
