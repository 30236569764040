import { ObserveVisibility } from 'vue-observe-visibility';

export default function observerPlugin(app) {
  app.directive('observe-visibility', {
    beforeMount: (el, binding, vnode) => {
      vnode.context = binding.instance;
      ObserveVisibility.bind(el, binding, vnode);
    },
    update: ObserveVisibility.update,
    unmounted: ObserveVisibility.unbind
  });
}
