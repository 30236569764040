/**
 * Simulates the let function in Kotlin.
 * Executes a block of code on an object if not null or undefined and returns the result of the block.
 * @template T, R
 * @param {T} object - The object to operate on.
 * @param {function(T): R} fn - The function to execute on the object.
 * @returns {R} The result of executing the block on the object.
 */
export const letIf = (object, fn) =>
  object !== null && object !== undefined ? fn(object) : object;
