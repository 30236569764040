export function accessNested(schema, path) {
  const pList = path.split('.');
  const len = pList.length;
  for (let i = 0; i < len - 1; i++) {
    const elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }

  const key = pList[len - 1];
  return [schema[key], value => (schema[key] = value)];
}

export function getOfObject(schema, path) {
  const pList = path.split('.');
  const len = pList.length;
  for (let i = 0; i < len - 1; i++) {
    const elem = pList[i];
    if (!schema[elem]) return undefined;
    schema = schema[elem];
  }

  return schema[pList[len - 1]];
}

export function setOnObject(schema, path, value) {
  return accessNested(schema, path)[1](value);
}

export function mapValues(obj, mapper) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, mapper(value, key)])
  );
}

export const isEmpty = obj => Object.keys(obj).length === 0;
