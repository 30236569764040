import { inputToCountry } from '@/config/countriesAndLocales.js';

export const getUtmSource = route =>
  route.query['utm_source'] ??
  (new URLSearchParams(window.location.search)?.get('utm_source') || undefined);

export const getCountry = route =>
  inputToCountry(route.params.countryOrTenantId);

export const getDomain = () => window.location.hostname.replace('www.', '');
