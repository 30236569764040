import countryCode from './isoCountryCodes.js';
import languageCode from './isoLanguageCodes.js';

const locale = (language, region, defaultRegion, name) => ({
  language,
  region,
  name,
  defaultRegion,
  code: language + (region ? '-' + region : '')
});

export default Object.fromEntries(
  [
    locale(languageCode.cs, null, countryCode.CZ, 'Český'),
    locale(languageCode.de, countryCode.AT, null, 'Österreichisch (formell)'),
    locale(languageCode.de, null, countryCode.DE, 'Deutsch'),
    locale(languageCode.en, countryCode.UK, null, 'British English'),
    locale(languageCode.en, null, countryCode.GB, 'English'),
    locale(languageCode.es, null, countryCode.ES, 'Español'),
    locale(languageCode.fr, countryCode.BE, null, 'Français (BE)'),
    locale(languageCode.fr, null, countryCode.FR, 'Français'),
    locale(languageCode.hr, null, countryCode.HR, 'Hrvatski'),
    locale(languageCode.hu, null, countryCode.HU, 'Magyar nyelv'),
    locale(languageCode.it, null, countryCode.IT, 'Italiano'),
    locale(languageCode.nl, countryCode.BE, null, 'Nederlands (BE)'),
    locale(languageCode.nl, null, countryCode.NL, 'Nederlands'),
    locale(languageCode.pl, null, countryCode.PL, 'Polski'),
    locale(languageCode.ro, null, countryCode.RO, 'Romanian'),
    locale(languageCode.ru, null, countryCode.RU, 'Русский'),
    locale(languageCode.sk, null, countryCode.SK, 'Slovenčina'),
    locale(languageCode.sl, null, countryCode.SI, 'Slovenščina'),
    locale(languageCode.sr, null, countryCode.RS, 'Serbian')
  ].map(locale => [locale.code, locale])
);
