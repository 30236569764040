<template>
  <section class="v-col-12 pa-0 ma-0">
    <span v-if="alert.type === 'success'">
      <v-alert
        variant="outlined"
        density="compact"
        :closable="true"
        :value="!!alert"
        type="success"
        >{{ alert.msg }}
        <template v-slot:close>
          <v-icon
            v-on:click="closeAction"
            class="v-icon notranslate mdi mdi-close-circle theme--light text-success"
            >mdi-close</v-icon
          >
        </template>
      </v-alert>
    </span>
    <span v-else-if="alert.type === 'error'">
      <v-alert
        :value="!!alert"
        type="error"
        :closable="true"
        variant="outlined"
        density="compact"
      >
        {{ alert.msg }}
        <template v-slot:close>
          <v-icon
            v-on:click="closeAction"
            :aria-label="$t('alert.hide')"
            class="v-icon notranslate mdi mdi-close-circle theme--light text-error"
            >mdi-close</v-icon
          >
        </template>
      </v-alert>
    </span>
    <span v-else-if="alert.type === 'info'">
      <v-alert :value="!!alert" type="info">{{ alert.msg }}</v-alert>
    </span>
    <span v-else-if="alert.type === 'warning'">
      <v-alert :value="!!alert" type="warning">{{ alert.msg }}</v-alert>
    </span>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { ACTION_TYPE_ERROR, ACTION_TYPE_SUCCESS } from '@/store/constants';

export default {
  name: 'AlertComponent',
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      alert: {
        msg: '',
        type: null
      }
    };
  },
  methods: {
    ...mapActions(['setMessage']),
    cleanAlert() {
      this.alert.type = null;
      this.alert.msg = '';
    },
    closeAction() {
      this.cleanAlert();
      this.setMessage({});
    },
    setAlert(newMessage) {
      if (newMessage.type === ACTION_TYPE_ERROR) {
        if (newMessage.type && newMessage.msg?.id) {
          this.alert.msg = this.$t(
            `errorHandling.messages.${newMessage.msg.id}`
          );
        } else {
          this.alert.msg = this.$t('errorHandling.messages.generic');
        }
        this.alert.type = newMessage.type;
      }
      if (newMessage.type === ACTION_TYPE_SUCCESS) {
        if (newMessage.type && newMessage.msg && newMessage.msg.id) {
          this.alert.msg = this.$t(newMessage.msg.id);
        } else {
          this.alert.msg = this.$t('generic.successMessage');
        }
        this.alert.type = newMessage.type;
      }
    }
  },
  watch: {
    message(newMessage) {
      if (Object.keys(newMessage).length > 0) {
        this.setAlert(newMessage);
      } else {
        this.cleanAlert();
      }
    }
  }
};
</script>

<style scoped></style>
