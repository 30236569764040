import each from 'lodash/each';

class HttpResponse {
  body = null;

  headers = {};

  statusCode = null;

  constructor(statusCode) {
    this.statusCode = statusCode;
  }

  getBody() {
    return this.body;
  }

  setBody(body) {
    this.body = body;
  }

  getHeader(name, defaultValue) {
    const headerName = name.toLowerCase();

    if (Object.prototype.hasOwnProperty.call(this.headers, headerName)) {
      return this.headers[headerName];
    }

    return defaultValue !== undefined ? defaultValue : null;
  }

  setHeader(name, value) {
    this.headers[name.toLowerCase()] = value;
  }

  getHeaders() {
    return this.headers;
  }

  setHeaders(headers) {
    each(headers, (value, name) => {
      this.headers[name.toLowerCase()] = value;
    });
  }

  getStatusCode() {
    return this.statusCode;
  }
}

export default HttpResponse;
