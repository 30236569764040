<template>
  <div>
    <v-row class="v-col-12 pa-0 ma-0">
      <div
        v-if="!isBelowMobileBreakpoint && !$store.$isWidget"
        class="v-row v-col-12"
      >
        <h2 class="pa-0 mr-4 my-4" style="padding: 0 0 30px">
          {{
            $t(
              $te('overview.headerDelivered')
                ? 'overview.headerDelivered'
                : 'overview.header'
            )
          }}
        </h2>
      </div>
      <div
        v-if="isBelowMobileBreakpoint && !$store.$isWidget"
        class="v-row my-0"
      >
        <v-col
          :style="$rttI18n.isGlsItSelected ? { visibility: 'hidden' } : null"
          class="d-flex text-right align-center font-weight-bold"
        >
          <div>{{ $t('overview.title') }}</div>
        </v-col>
        <v-col class="d-flex align-center justify-end" style="flex-basis: 50%">
          <span class="font-weight-bold">{{ $t('generic.trackingId') }}</span
          >: {{ trackingData.trackingId }}
        </v-col>
      </div>
      <v-col
        class="pa-0"
        :class="isBelowMobileBreakpoint ? 'v-col-12' : 'v-col-6'"
      >
        <MapContainer v-if="!isBelowMobileBreakpoint" />
        <AdComponent
          v-if="
            !isBelowMobileBreakpoint &&
            ((trackingData.ad && trackingData.ad.length) || 0) < 2
          "
          :adData="trackingData.ad"
        />
      </v-col>
      <v-col
        class="pa-0"
        :class="isBelowMobileBreakpoint ? 'v-col-12' : 'v-col-6 pl-4'"
      >
        <OverviewComponent
          @toggleDialog="dialogHandler"
          v-if="
            !configGetter(this.$rttI18n.tenantId, getThemeId).disableOverview
          "
        />
        <FeedbackComponent
          v-if="
            trackingData.toAddress &&
            !configGetter(this.$rttI18n.tenantId, getThemeId).disableOverview
          "
          :ratingData="trackingData.rating"
          :trackingId="trackingData.trackingId"
          :postcode="trackingData.toAddress.postcode"
        />
        <MapContainer v-if="isBelowMobileBreakpoint" />
        <AdComponent
          v-if="
            isBelowMobileBreakpoint &&
            !configGetter(this.$rttI18n.tenantId, getThemeId).disableOverview
          "
          :adData="trackingData.ad"
        />
        <DeliveryAddressComponent
          v-if="
            !configGetter(this.$rttI18n.tenantId, getThemeId).disableOverview
          "
        />
      </v-col>
    </v-row>
    <AdComponent
      v-if="
        !isBelowMobileBreakpoint &&
        (trackingData.ad && trackingData.ad.length) >= 2 &&
        !configGetter(this.$rttI18n.tenantId, getThemeId).disableOverview
      "
      :adData="trackingData.ad"
    />
  </div>
</template>

<script>
import AdComponent from '../AdComponent';
import DeliveryAddressComponent from '../DeliveryAddressComponent';
import FeedbackComponent from '../feedback/FeedbackComponent';
import MapContainer from '@/components/map/MapContainer';
import OverviewComponent from '../OverviewComponent';
import { configGetter } from '@/config/configGetter';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'RealTimeTrackingStage2',
  components: {
    AdComponent,
    DeliveryAddressComponent,
    FeedbackComponent,
    MapContainer,
    OverviewComponent
  },
  data() {
    return {
      configGetter
    };
  },
  computed: {
    ...mapState(['trackingData', 'isBelowMobileBreakpoint']),
    ...mapGetters(['getThemeId'])
  },
  methods: {
    ...mapActions(['toggleDialog']),
    dialogHandler(dialog) {
      this.toggleDialog(dialog);
    }
  }
};
</script>

<style scoped></style>
