const stripTrailingSlash = str => {
  return str.endsWith('/') ? str.slice(0, -1) : str;
};

export default function publicPath(path) {
  return (
    (import.meta.env.BASE_URL
      ? stripTrailingSlash(import.meta.env.BASE_URL)
      : '') + path
  );
}
