import moment from 'moment-timezone';

export function easterSunday() {
  const year = new Date().getFullYear();
  let a, b, c, d, f;
  return new Date(
    year,
    ((d =
      56 -
      (c =
        (((year % 19) * 351 -
          ~((b = (a = (year / 100) | 0) >> 2) + a * 29.32 + 13.54) * 31.9) /
          33) %
          29 |
        0) -
      (~(a - b + c - 24 - year / 0.8) % 7)) > (f = 31)
      ? 4
      : 3) - 1,
    ((d - f > 0) & (d - f < 10) ? 0 : '') + (d > f ? d - f : d)
  );
}

const timeFrames = Object.entries({
  // and length of timeframe
  newYear: ['30.12.', '7.1.', 9],
  winter: ['10.12.', '7.1.', 28],
  winter2: ['8.1.', '30.1.', 22],
  snowboard: ['30.1.', '20.2.', 21],
  eastern: [
    moment(easterSunday()).subtract(7, 'days'),
    moment(easterSunday()),
    7
  ],
  summer: ['1.7.', '30.8.', 61],
  autumn: ['1.10.', '30.11.', 61],
  halloween: ['24.10.', '31.10.', 7],
  christmas: ['20.12.', '26.12.', 7]
  //test: ['1.1.', '31.12.', 1]
});

export function getSeasonOrEventTimeFrameName(now = new Date()) {
  const pivot = now.getDate() + (now.getMonth() + 1) * 100;

  let match,
    minTimeFrameLength = 999;
  const convert = date => {
    if (typeof date === 'string') {
      const [dayOfMonth, month] = date
        .substring(0, date.length - 1)
        .split('.')
        .map(d => parseInt(d));
      return dayOfMonth + month * 100;
    } else {
      return date.date() + date.month() * 100;
    }
  };

  timeFrames.forEach(function ([name, [from, to, length]]) {
    const fromPivot = convert(from);
    const toPivot = convert(to);
    if (
      !(fromPivot < toPivot
        ? fromPivot <= pivot && pivot <= toPivot
        : fromPivot <= pivot || pivot <= toPivot) ||
      length > minTimeFrameLength
    )
      return;

    match = name;
    minTimeFrameLength = length;
  });
  return match;
}
