import countries from '@/config/countries.js';
import isoLanguageCodes from '@/config/isoLanguageCodes.js';
import locales from '@/config/locales.js';

export function getLocaleValidationErrors(locale) {
  const isCorrectStringFormat =
    locale.match(/^[a-z]{2}$/) || locale.match(/^[a-z]{2}-[A-Z]{2}$/);
  if (!isCorrectStringFormat) {
    return 'Incorrect string format, should be de or de-DE.';
  }
  let validationErrors = '';
  const localeParts = locale.split('-');
  const language = localeParts[0];
  const isLanguageSupported = isoLanguageCodes[language];
  if (!isLanguageSupported) {
    validationErrors +=
      'Unsupported language, check config file isoLanguageCodes.js.';
  }
  const region = localeParts[1];
  if (region) {
    const isRegionSupported = countries[region];
    if (!isRegionSupported) {
      validationErrors +=
        ' Unsupported region, check config file isoCountryCodes.js.';
    }
  }
  const isLocaleSupported = locales[locale];
  if (!isLocaleSupported) {
    validationErrors += ' Unsupported locale, check config file locales.js.';
  }
  return validationErrors.trim();
}

export function validateLocale(locale) {
  const localeValidationErrors = getLocaleValidationErrors(locale);
  if (localeValidationErrors) {
    throw new Error(localeValidationErrors);
  }
}
