<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    class="progressArcRoot"
    :style="`width: ${this.size}px; height: ${size}px;`"
  >
    <path
      v-for="n in 9"
      :key="n"
      fill="none"
      :d="calculateArc(n)"
      :stroke-width="4"
      stroke-linecap="round"
      stroke="rgb(var(--v-theme-stopsContainer))"
      :opacity="9 * percent < n ? '0.3' : '1'"
    >
      <!-- Blinking stop part animation in case percentage > 0 -->
      <animate
        v-if="percent > 0 && Math.ceil(9 * percent) === n"
        attributeName="opacity"
        values="0.6;0.3;0.6"
        dur="1.5s"
        keyTimes="0; 0.5; 1"
        repeatCount="indefinite"
      />
      <!-- Clockwise progress animation in case 0 percentage -->
      <animate
        v-if="percent === 0"
        attributeName="opacity"
        :values="getAnimationValues(n)"
        dur="2s"
        :keyTimes="Array.from({ length: 11 }, (_, i) => i / 10).join(';')"
        repeatCount="indefinite"
      />
    </path>
  </svg>
</template>
<script>
import { f_svg_ellipse_arc } from '@/services/svg';

export default {
  name: 'ProgressArc',
  props: {
    size: {
      type: Number,
      default: 100
    },
    percent: {
      type: Number,
      default: 0
    },
    strokeWidth: {
      type: Number,
      default: 20
    }
  },
  data: function () {
    return {};
  },
  methods: {
    calculateArc(part) {
      const halfViewport = 50;
      const inset = 2;
      const outsetStrokeSize = this.strokeWidth / 2;
      const degToRad = deg => (deg / 360) * 2 * Math.PI;

      return f_svg_ellipse_arc(
        [halfViewport, halfViewport],
        [
          halfViewport - outsetStrokeSize - inset,
          halfViewport - outsetStrokeSize - inset
        ],
        [degToRad((360 / 9) * (part - 1)), degToRad(30)],
        degToRad(275)
      );
    },
    getAnimationValues(part) {
      return Array.from({ length: 11 }, (_, i) => {
        const pos = i + 1;
        if (pos < part) return '0.3';
        else if (pos === part) return '0.6';
        else if (pos === part + 1) return '0.9';
        else return '1';
      }).join(';');
    }
  }
};
</script>
<style scoped>
.progressArcRoot {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
}
</style>
