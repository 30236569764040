const env = import.meta.env.VITE_APP_ENV;
const reviewAppId = import.meta.env.VITE_APP_REVIEW_APP_ID;
import countriesAndLanguages from './countriesAndLocales.js';

const config = {
  env,
  i18n: countriesAndLanguages,
  themeConfig: {
    bettermile: {
      disablePoweredBy: true
    }
  },
  timezone: {
    default: 'Europe/Berlin'
  },
  // this property is set during initialization of the widget
  widgetConfiguration: {},
  reviewAppId: reviewAppId
};

export function setWidgetConfig(widgetConfig) {
  config.widgetConfiguration = widgetConfig;
}

export default config;
