<template>
  <div>
    <div
      v-if="this.showBanner()"
      class="eta-alert"
      :class="{
        'very-early':
          this.trackingData.status?.etaChangeFactor ===
          ETA_DEVIATION_VERY_EARLY,
        'very-delayed':
          this.trackingData.status?.etaChangeFactor ===
          ETA_DEVIATION_VERY_DELAYED
      }"
    >
      <v-icon
        class="info-icon"
        :class="{
          'very-early':
            this.trackingData.status?.etaChangeFactor ===
            ETA_DEVIATION_VERY_EARLY,
          'very-delayed':
            this.trackingData.status?.etaChangeFactor ===
            ETA_DEVIATION_VERY_DELAYED
        }"
        :icon="mdiInformationOutline"
      ></v-icon>
      <div>{{ $t(deviationI18nLabel) }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  ETA_DEVIATION_VERY_DELAYED,
  ETA_DEVIATION_VERY_EARLY
} from '@/store/constants';
import { sendAnalytics } from '@/services/analytics';
import { mdiInformationOutline } from '@mdi/js';

const i18nKeys = {
  [ETA_DEVIATION_VERY_EARLY]: 'etaInformation.banner.veryEarly',
  [ETA_DEVIATION_VERY_DELAYED]: 'etaInformation.banner.bigDelay'
};

export default {
  name: 'EtaInformationBanner',
  data() {
    return {
      ETA_DEVIATION_VERY_EARLY,
      ETA_DEVIATION_VERY_DELAYED,
      mdiInformationOutline
    };
  },
  computed: {
    ...mapState(['trackingData']),
    deviationI18nLabel() {
      const label = i18nKeys[this.trackingData.status?.etaChangeFactor];
      if (!label) return null;

      sendAnalytics(this.trackingData, 'eta', 'deviation_alert_displayed', {
        changeFactor: this.trackingData.status?.etaChangeFactor
      });
      return label;
    }
  },
  methods: {
    showBanner() {
      return this.deviationI18nLabel;
    }
  }
};
</script>

<style scoped>
div.eta-alert {
  display: flex;
  gap: 1em;
  font-size: 0.9em;
  border-radius: var(--border-radius-root);
  border: 0.1em solid;
  padding: 1em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  margin-bottom: 1.5em;
}

div.eta-alert.very-early {
  border-color: #ff860d;
  background-color: #faeee2;
}

div.eta-alert.very-delayed {
  border-color: #e78faa;
  background-color: #f7f0f2;
}
div.eta-alert .info-icon {
  font-size: 1.8em;
}

.info-icon.very-early {
  color: #ff860d;
}
.info-icon.very-delayed {
  color: #e78faa;
}
</style>
