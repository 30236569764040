// backendDefaultEndpoint
const API_URL = `${
  import.meta.env.VITE_APP_API_URL || window.location.origin
}/v1`;

export const MOBILE_BREAKPOINT = 960;
export const TABLET_BREAKPOINT = 1280;

export const TRACKING_DATA_BY_ID_PATH = `${API_URL}/tenant/{tenant}/parcel/{trackingId}`;
export const TRACKING_DATA_BY_ID_AND_ZIPCODE_PATH = `${API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}`;
export const CUSTOMIZE_DELIVERY_PATH = `${API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}/customization`;
export const ADD_RATING_PATH = `${API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}/rating`;
export const NOTIFICATION_SUBSCRIPTION_PATH = `${API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}/notification/sms`;
export const PUSH_NOTIFICATION_SUBSCRIPTION_PATH = `${API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}/notification/push`;
export const PUSH_NOTIFICATION_SUBSCRIPTION_UPDATE_PATH = `${API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}/notification/push/update`;
export const ANALYTICS_PATH = `${API_URL}/analytics`; // also adapt path in push-worker.js
export const TIME_FRAME_FAILURE_PATH = `${API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}/time-frame`;
export const TIPPING_CREATE_ORDER_PATH = `${API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}/tipping/order`;
export const TIPPING_CAPTURE_ORDER_PATH = `${API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}/tipping/capture`;

export const TRACKING_STATE_THRESHOLD_MED = 10;
export const TRACKING_STATE_THRESHOLD_MIN = 1;
export const TRACKING_STATE_THRESHOLD_BLURRY = 0;

export const TRACKING_STATE_DELIVERED = 'DELIVERED';
export const TRACKING_STATE_NOT_DELIVERED = 'NOT_DELIVERED';
export const TRACKING_STATE_OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY';
export const TRACKING_STATE_UNKNOWN = 'UNKNOWN';
export const TRACKING_STATE_BLOCKED = 'BLOCKED';
export const TRACKING_STATE_SHADOWED = 'SHADOWED';

export const JOBTYPE_PICKUP = 'PICKUP';

export const TRACKING_STATE_PICKED_UP = 'PICKED_UP';
export const TRACKING_STATE_NOT_PICKED_UP = 'NOT_PICKED_UP';

export const TRACKING_DELIVERY_STATE_FAR = 'far';
export const TRACKING_DELIVERY_STATE_CLOSE = 'close';
export const TRACKING_DELIVERY_STATE_NEAR = 'near';
export const TRACKING_DELIVERY_STATE_LAST = 'last';
export const TRACKING_DELIVERY_STATE_UNCLEAR = 'unclear';

export const TRACKING_STATE_DETAIL_PARCEL_SHOP = 'PARCEL_SHOP';
export const TRACKING_STATE_DETAIL_NEIGHBOR = 'NEIGHBOR';

// delta in milliseconds
export const TRACKING_STATE_ETA_VALID_DELTA = -(15 * 60 * 1000); // 15 minutes past

export const NOTIFICATION_TYPE_ELIGIBLE = 'ELIGIBLE';
export const NOTIFICATION_TYPE_SET = 'SET';
export const DELIVER_TO_NEIGHBOUR = 'DELIVER_TO_NEIGHBOR';
export const DEPOSIT_PERMISSION = 'DEPOSIT_PERMISSION';
export const ACTION_CUSTOMIZED_DELIVERY = 'customized_delivery';
export const ACTION_NOTIFICATION_SUBSCRIPTION = 'notification_subscribed';
export const ACTION_PUSH_NOTIFICATION_SUBSCRIPTION =
  'push_notification_subscribed';

export const ACTION_TYPE_INFO = 'info';
export const ACTION_TYPE_ERROR = 'error';
export const ACTION_TYPE_SUCCESS = 'success';

export const PROCESS_ADD_CUSTOMIZED_DELIVERY = 'add_customized_delivery';
export const PROCESS_ADD_RATING = 'add_rating';
export const PROCESS_ADD_NOTIFICATION_SUBSCRIPTION =
  'add_notification_subscription';
export const PROCESS_LOAD_ADDITIONAL_DELIVERY_DATA =
  'load_additional_delivery_data';
export const PROCESS_LOAD_TRACKING_DATA = 'load_tracking_data';
export const PROCESS_LOAD_TRIGGER_TRACKING_URL = 'trigger_tracking_url';

export const LOCAL_STORAGE_TENANT_KEY = 'rtt.selectedTenant';
export const LOCAL_STORAGE_LOCALE_KEY = 'rtt.selectedLocale';
export const LOCAL_STORAGE_GDPR_KEY = `rtt.gdpr`;

export const ETA_DEVIATION_VERY_EARLY = 'VERY_EARLY';
export const ETA_DEVIATION_VERY_DELAYED = 'VERY_DELAYED';
export const UPDATE_TRACKING_EVENT = 'UPDATE_TRACKING_EVENT';

export const DELIVERY_NOTE = 'HINT';
