import {
  ACTION_CUSTOMIZED_DELIVERY,
  ACTION_NOTIFICATION_SUBSCRIPTION,
  ACTION_PUSH_NOTIFICATION_SUBSCRIPTION,
  ACTION_TYPE_INFO,
  LOCAL_STORAGE_GDPR_KEY,
  TRACKING_STATE_THRESHOLD_MED
} from './constants';
import { defaultThemeName } from '@/config/themes.js';
import { configGetter } from '@/config/configGetter.js';

export default {
  decrementProcesses(state, process) {
    const processName = process ? process : 'root';
    if (!state.loadingProcesses[processName]) {
      state.loadingProcesses[processName] = 0;
    } else {
      state.loadingProcesses[processName] -= 1;
    }
  },
  incrementProcesses(state, process) {
    const processName = process ? process : 'root';
    if (!state.loadingProcesses[processName]) {
      state.loadingProcesses[processName] = 1;
    } else {
      state.loadingProcesses[processName] += 1;
    }
  },
  updateMessage(state, message) {
    state.message = message;
  },
  updateThemeId(state, themeId) {
    state.themeId = themeId || defaultThemeName();
  },
  updateAdditionalDeliveryData(state, payload) {
    state.trackingData = null;
    state.trackingData = payload;
  },
  updateCustomizedDelivery(state, payload) {
    if (payload.customizedDelivery) {
      state.trackingData.customizedDelivery = payload.customizedDelivery;
      state.message = {
        msg: ACTION_CUSTOMIZED_DELIVERY,
        type: ACTION_TYPE_INFO
      };
    }
  },
  updateNotificationSubscription(state, payload) {
    state.message = {
      msg: ACTION_NOTIFICATION_SUBSCRIPTION,
      type: ACTION_TYPE_INFO
    };
    state.trackingData.smsNotification = {
      type: 'SET',
      phone: payload.body.phone
    };
  },
  updatePushNotification(state, payload) {
    state.message = {
      msg: ACTION_PUSH_NOTIFICATION_SUBSCRIPTION,
      type: ACTION_TYPE_INFO
    };
    state.trackingData.pushNotification = {
      state: 'SET',
      minutesBeforeDelivery: payload.minutesBeforeDelivery
    };
  },
  updateFailure(state, payload) {
    state.message = payload;
  },
  updateIsBelowMobileBreakpoint(state, payload) {
    state.isBelowMobileBreakpoint = payload;
  },
  updateIsBelowTabletBreakpoint(state, payload) {
    state.isBelowTabletBreakpoint = payload;
  },
  updateGdprConsent(state, payload) {
    state.gdprConsent[payload.tenantId] = payload.value;
    if (!configGetter(payload.tenantId).resetGdpr || payload.value === false) {
      localStorage.setItem(
        LOCAL_STORAGE_GDPR_KEY,
        JSON.stringify(state.gdprConsent)
      );
    }
  },
  toggleNPSConsent(state) {
    state.NPSConsent = !state.NPSConsent;
  },
  updateCustomizedDeliveryPeriodMutation(state, payload) {
    const key = `rtt.deliveryPeriodBeta.${payload.trackingId}`;
    localStorage.setItem(key, payload.deliveryPeriod ?? '');
    state.deliveryPeriod = payload.deliveryPeriod;
  },
  readCustomizedDeliveryPeriodMutation(state, payload) {
    const key = `rtt.deliveryPeriodBeta.${payload.trackingId}`;
    state.deliveryPeriod = localStorage.getItem(key);
  },
  updateRatingData(state, payload) {
    state.trackingData.rating = payload;
  },
  updatePersonalTrackingData(state, payload) {
    //state.trackingData = null;
    state.trackingData = payload;
    state.trackingData.updateTimestamp = new Date().getTime();
    state.isPrivate = true;
  },
  updateTrackingData(state, payload) {
    state.trackingData = null;
    state.trackingData = {
      ...payload,
      // 'maxStopsToShowDriverLocation' is only part of the private parcel response
      maxStopsToShowDriverLocation:
        payload.maxStopsToShowDriverLocation ?? TRACKING_STATE_THRESHOLD_MED,
      updateTimestamp: new Date().getTime()
    };
    state.isPrivate = false;
  },
  toggleDialog(state, payload) {
    let dialog = payload.dialog || payload;
    if (dialog === state.activeDialog) {
      dialog = null;
    }
    const dialogPayload = payload.payload;
    if (dialogPayload) {
      state.dialogPayload = dialogPayload;
    }
    state.activeDialog = dialog;
  }
};
