<template>
  <div :class="['px-0', isBelowMobileBreakpoint ? 'py-2' : 'py-0']">
    <a
      v-if="!adData.body"
      @click="sendClickAnalytics('ad_image_clicked')"
      @contextmenu.prevent="
        openLink();
        sendClickAnalytics('ad_image_clicked');
      "
      :href="adData.redirect_url"
      :target="adData.target"
    >
      <ConditionalCard
        :wrap="!!(adData.metadata && adData.metadata.card)"
        :cls="cls"
      >
        <v-img
          cover
          :src="adData.image_url"
          :alt="adData.alt_text"
          :max-height="adData.height > 0 ? adData.height : 'auto'"
          :max-width="adData.width > 0 ? adData.width : 'auto'"
          width="100%"
          v-observe-visibility="{
            callback: visibilityChanged,
            once: true
          }"
        />
      </ConditionalCard>
    </a>
    <ConditionalCard
      :wrap="!!(adData.metadata && adData.metadata.card)"
      :cls="cls"
      v-if="adData.body"
    >
      <div @click="videoHandling" :style="'position: relative'">
        <div
          ref="adDataBody"
          v-html="adData.body"
          v-observe-visibility="{
            callback: visibilityChanged,
            once: true
          }"
        />
        <div v-if="!playing" class="videoButton" />
      </div>
    </ConditionalCard>

    <v-btn
      v-if="adData.metadata && adData.metadata.button"
      @click="sendClickAnalytics('ad_button_clicked')"
      @contextmenu.prevent="
        openLink();
        sendClickAnalytics('ad_button_clicked');
      "
      :target="adData.target"
      :href="adData.redirect_url"
      :block="true"
      color="info"
      class="mt-6"
    >
      {{ adData.metadata.button }}
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ConditionalCard from '@/components/ConditionalCard';
import { sendAnalytics } from '@/services/analytics';

export default {
  name: 'AdItem',
  components: {
    ConditionalCard
  },
  props: {
    adData: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      played: false,
      playing: false
    };
  },
  computed: {
    ...mapState(['isBelowMobileBreakpoint', 'trackingData']),
    cls() {
      if (this.adData.metadata && this.adData.metadata.align) {
        const align = this.adData.metadata.align;
        if (align === 'left') return 'd-flex justify-start';
        else if (align === 'right') return 'd-flex justify-end';
      }
      return 'd-flex justify-center';
    }
  },
  methods: {
    ...mapActions(['triggerTrackingUrl']),
    triggerViewableUrl() {
      this.sendClickAnalytics('ad_viewable');
      this.triggerTrackingUrl({ url: this.adData.viewable_url });
    },
    triggerEligibleUrl() {
      this.sendClickAnalytics('ad_eligible');
      this.triggerTrackingUrl({ url: this.adData.eligible_url });
    },
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.triggerViewableUrl();
      }
    },
    videoHandling(evt) {
      const videoElement = evt.currentTarget.querySelector('video');
      this.playing = videoElement.paused;

      if (videoElement.paused) {
        videoElement.play();
        if (!this.played) {
          this.sendClickAnalytics('video_ad_started');
          this.played = true;
        }
      } else {
        videoElement.pause();
        window.open(this.adData.redirect_url, this.adData.target);
        this.sendClickAnalytics('video_ad_clicked');
      }
    },
    sendClickAnalytics(eventName) {
      sendAnalytics(this.trackingData, 'ads', eventName, {
        banner_id: this.adData.banner_id,
        alt_text: this.adData.alt_text
      });
    },
    openLink() {
      window.open(this.adData.redirect_url, '_blank');
    }
  },
  mounted() {
    if (this.adData !== null) {
      this.triggerEligibleUrl();
    }
    if (this.$refs.adDataBody) {
      const videoElement = this.$refs.adDataBody.querySelector('video');
      this.playing = videoElement?.paused === false;
      if (this.playing) {
        this.triggerViewableUrl();
        this.played = true;
      }
    }
  }
};
</script>

<style scoped>
.videoButton {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('/video-play-button.svg');
  background-position: center;
}
</style>
