<template>
  <v-col class="pa-0">
    <div
      :style="$rttI18n.isGlsItSelected ? { visibility: 'hidden' } : null"
      v-if="!isBelowMobileBreakpoint && !$store.$isWidget"
      class="py-4"
    >
      <span class="font-weight-bold">{{ $t('overview.title') }}</span>
    </div>

    <EtaInformationBanner />
    <v-card class="card_big v-col-12 overview mb-6" data-testid="overview-card">
      <div
        v-if="
          getTrackingState === TRACKING_DELIVERY_STATE_CLOSE ||
          getTrackingState === TRACKING_DELIVERY_STATE_FAR ||
          getTrackingState === TRACKING_DELIVERY_STATE_NEAR ||
          (getTrackingState === TRACKING_STATE_SHADOWED && getTimeframe)
        "
      >
        <p v-if="getTrackingState === TRACKING_DELIVERY_STATE_FAR">
          <span>
            {{
              $t('overview.details.stateFar', {
                stops: trackingData.trackingStateThresholdMax
              })
            }}
          </span>
        </p>
        <p
          class="text-footnote mb-4"
          v-if="
            getTrackingState === TRACKING_DELIVERY_STATE_CLOSE ||
            getTrackingState === TRACKING_DELIVERY_STATE_NEAR ||
            getTrackingState === TRACKING_STATE_SHADOWED
          "
        >
          <span v-if="getTimeframe">{{
            $rttI18n.tenantSpecificWithFallback(
              !isServiceTypePickup
                ? 'overview.details.deliveryTimestamp'
                : 'overview.details.pickupTimestamp'
            )
          }}</span>
          <span v-else>{{
            $t(
              !isServiceTypePickup
                ? 'overview.details.unclear.description'
                : 'overview.details.unclearPickup.description'
            )
          }}</span>
        </p>
        <h3 class="text-h3" v-if="getTimeframe">
          {{ this.timeFrameDesc }}
          <DynamicTourInfoIcon />
        </h3>
        <v-btn
          v-if="trackingData.timeFrameEligible"
          :disabled="hasCustomizedDeliveryPeriod()"
          data-testid="btn-customizeDeliveryPeriod"
          color="primary"
          :block="true"
          class="text-body-1 my-4"
          v-on:click="dialogHandler(dialogs.DELIVERY_PERIOD)"
        >
          <span>{{ $t('overview.details.customizeDeliveryPeriod') }}</span>
          <img
            alt="Delivered Period Icon"
            src="/icon_delivery_period.svg"
            class="deliveryPeriodIcon"
          />
        </v-btn>
        <hr v-else class="my-5" />
        <LastUpdatedComponent :updateTimestamp="trackingData.updateTimestamp" />
      </div>

      <div
        :class="[$rttI18n.isGlsItSelected ? 'd-flex flex-column-reverse' : '']"
        v-if="getTrackingState === this.TRACKING_DELIVERY_STATE_LAST"
      >
        <LastUpdatedComponent :updateTimestamp="trackingData.updateTimestamp" />
        <hr class="my-5" />
        <p v-if="$rttI18n.isGlsItSelected" class="font-weight-bold">
          {{ overviewHeader }}
        </p>
        <h3 v-else class="text-h3">
          {{ overviewHeader }}
        </h3>
      </div>

      <div
        :class="[reverseStateInfoLayout() ? 'd-flex flex-column-reverse' : '']"
        v-if="
          getTrackingState === this.TRACKING_DELIVERY_STATE_UNCLEAR ||
          getTrackingState === TRACKING_STATE_BLOCKED ||
          (getTrackingState === TRACKING_STATE_SHADOWED && !getTimeframe)
        "
      >
        <p>
          <LastUpdatedComponent
            :updateTimestamp="trackingData.updateTimestamp"
          />
        </p>
        <hr class="my-5" />
        <p v-if="$rttI18n.isGlsItSelected" class="font-weight-bold">
          {{ unclearHeader }}
        </p>
        <p v-else>
          <span class="font-weight-bold timeframe my-4">
            {{ unclearHeader }}</span
          >
        </p>
      </div>

      <OverviewDeliveredComponent :postcode="postcode" />

      <OverviewPickedUpComponent :postcode="postcode" />

      <div v-if="getTrackingState === this.TRACKING_STATE_NOT_DELIVERED">
        <img
          alt="Icon not delivered"
          :src="
            publicPath(`/themes/${this.getThemeId}/icon_delivery_refused.svg`)
          "
          style="width: 60px"
        />
        <p class="header">
          <span class="font-weight-bold">{{
            $t('overview.details.notDelivered.header')
          }}</span>
        </p>
        <div
          :class="[
            $rttI18n.isGlsItSelected ? 'd-flex flex-column-reverse' : ''
          ]"
        >
          <hr class="my-5" />
          <p class="font-weight-bold mb-0" v-if="$rttI18n.isRoyalMailSelected">
            {{
              $rttI18n.tenantSpecificWithFallback(
                'overview.details.goBackToDetails'
              )
            }}
          </p>
          <p v-else>
            <span>{{
              $t(
                `overview.details.notDelivered.reasons.${trackingData.status.detail}`
              )
            }}</span>
          </p>
        </div>
      </div>

      <div v-if="getTrackingState === this.TRACKING_STATE_NOT_PICKED_UP">
        <img
          alt="Icon not delivered"
          :src="
            publicPath(`/themes/${this.getThemeId}/icon_delivery_refused.svg`)
          "
          style="width: 60px"
        />
        <p class="header">
          <span class="font-weight-bold">{{
            $t('overview.details.notPickedUp.header')
          }}</span>
        </p>
        <hr class="my-5" />
        <div
          :class="[
            $rttI18n.isGlsItSelected ? 'd-flex flex-column-reverse' : ''
          ]"
        >
          <p>
            <LastUpdatedComponent
              :updateTimestamp="trackingData.updateTimestamp"
            />
          </p>
          <hr class="my-5" />
          <p>
            <span>{{
              $t(
                `overview.details.notPickedUp.reasons.${trackingData.status.detail}`
              )
            }}</span>
          </p>
        </div>
      </div>

      <div
        :class="[$rttI18n.isGlsItSelected ? 'd-flex flex-column-reverse' : '']"
        v-if="getTrackingState === this.TRACKING_STATE_UNKNOWN"
      >
        <template v-if="this.$rttI18n.isRoyalMailSelected">
          <p class="font-weight-bold timeframe mb-0">
            {{ $t('overview.details.unclear.header') }}
          </p>
          <hr class="my-5" />
          <p>
            <LastUpdatedComponent
              :updateTimestamp="trackingData.updateTimestamp"
            />
          </p>
        </template>
        <template v-else>
          <p>
            <LastUpdatedComponent
              :updateTimestamp="trackingData.updateTimestamp"
            />
          </p>
          <p>
            <span class="font-weight-bold timeframe">{{
              $rttI18n.tenantSpecificWithFallback(
                'overview.details.unknown.header'
              )
            }}</span>
          </p>
          <hr class="my-5" />
          <p>
            <span>{{ $t('overview.details.unknown.description') }}</span>
          </p>
        </template>
      </div>
    </v-card>
    <div class="tipping-container" v-if="trackingData.tippingEnabled">
      <TippingComponent
        v-if="!tippingClosed"
        @closeTipping="this.handleCloseTipping"
      />
    </div>
    <div
      v-if="
        trackingData.toAddress &&
        trackingData.toAddress.postcode &&
        getTrackingState !== TRACKING_STATE_DELIVERED &&
        getTrackingState !== TRACKING_STATE_NOT_DELIVERED &&
        getTrackingState !== TRACKING_STATE_UNKNOWN
      "
    >
      <div
        v-if="
          isBelowMobileBreakpoint &&
          showCustomizationButtons &&
          trackingData.supportedCustomizedDeliveryTypes.length > 0 &&
          !(
            configGetter(this.$rttI18n.tenantId, getThemeId)
              .disableDepositPermission &&
            configGetter(this.$rttI18n.tenantId, getThemeId)
              .disableDeliverToNeighbour
          )
          // add disableSmsDelivery if sms are used again
        "
        class="my-4"
      >
        <v-btn
          data-testid="btn-customizeDelivery"
          color="info"
          :block="true"
          class="text-body-1"
          v-on:click="dialogHandler(dialogs.CUSTOMIZE)"
        >
          {{ $t('overview.details.customizeDelivery') }}
        </v-btn>
      </div>
      <div v-if="!isBelowMobileBreakpoint">
        <v-btn
          color="info"
          :block="true"
          class="text-body-1 my-4"
          v-if="
            trackingData.supportedCustomizedDeliveryTypes.includes(
              DEPOSIT_PERMISSION
            ) &&
            !configGetter(this.$rttI18n.tenantId, getThemeId)
              .disableDepositPermission &&
            showCustomizationButtons
          "
          v-on:click="dialogHandler(dialogs.DROP_OFF)"
        >
          {{ $t('customization.dropOffPermission.header') }}
        </v-btn>
        <v-btn
          color="info"
          :block="true"
          class="text-body-1 my-4 neighbor-delivery"
          v-on:click="dialogHandler(dialogs.NEIGHBOUR)"
          v-if="
            trackingData.supportedCustomizedDeliveryTypes.includes(
              DELIVER_TO_NEIGHBOUR
            ) &&
            !configGetter(this.$rttI18n.tenantId, getThemeId)
              .disableDeliverToNeighbour &&
            showCustomizationButtons
          "
        >
          {{ $t('customization.neighbour.header') }}
        </v-btn>

        <v-btn
          v-if="
            !$store.$isWidget &&
            getTrackingState !== TRACKING_STATE_DELIVERED &&
            trackingData.supportedCustomizedDeliveryTypes.includes(
              DELIVERY_NOTE
            ) &&
            showCustomizationButtons
          "
          color="info"
          :block="true"
          class="text-body-1"
          v-on:click="dialogHandler(dialogs.DELIVERY_NOTE)"
        >
          {{ $t('customization.deliveryHints.header') }}
        </v-btn>
      </div>
      <div
        v-if="
          trackingData.smsNotification.type === NOTIFICATION_TYPE_ELIGIBLE &&
          getTrackingState !== TRACKING_DELIVERY_STATE_LAST
        "
        class="actions my-4"
      >
        <v-btn
          color="primary"
          :block="true"
          class="text-body-1"
          v-on:click="dialogHandler(dialogs.NOTIFICATION_SUBSCRIPTION)"
        >
          {{ $t('customization.notificationSubscription.header') }}
        </v-btn>
      </div>
      <div
        v-if="trackingData.smsNotification.type === NOTIFICATION_TYPE_SET"
        class="pt-4"
      >
        <v-card class="card_small v-col-12">
          <span
            v-html="
              $t(
                'customization.notificationSubscription.info',
                trackingData.smsNotification
              )
            "
          />
        </v-card>
      </div>
      <div
        v-if="
          trackingData.pushNotification.state === NOTIFICATION_TYPE_ELIGIBLE &&
          getTrackingState !== TRACKING_DELIVERY_STATE_LAST &&
          supportsPushNotification &&
          !$store.$isWidget
        "
        class="actions my-4"
      >
        <v-btn
          color="primary"
          :block="true"
          class="text-body-1"
          v-on:click="dialogHandler(dialogs.PUSH_NOTIFICATION_SUBSCRIPTION)"
        >
          {{ $t('pushNotification.button') }}
        </v-btn>
      </div>

      <div
        v-if="trackingData.pushNotification.state === NOTIFICATION_TYPE_SET"
        class="pt-4"
      >
        <v-card class="card_small v-col-12">
          <span
            v-html="$t('pushNotification.info', trackingData.pushNotification)"
          />
        </v-card>
      </div>
    </div>
  </v-col>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment-timezone';
import LastUpdatedComponent from './LastUpdatedComponent';
import OverviewDeliveredComponent from '@/components/OverviewDeliveredComponent';
import {
  DELIVER_TO_NEIGHBOUR,
  DELIVERY_NOTE,
  DEPOSIT_PERMISSION,
  NOTIFICATION_TYPE_ELIGIBLE,
  NOTIFICATION_TYPE_SET,
  TRACKING_DELIVERY_STATE_CLOSE,
  TRACKING_DELIVERY_STATE_FAR,
  TRACKING_DELIVERY_STATE_LAST,
  TRACKING_DELIVERY_STATE_NEAR,
  TRACKING_DELIVERY_STATE_UNCLEAR,
  TRACKING_STATE_BLOCKED,
  TRACKING_STATE_DELIVERED,
  TRACKING_STATE_DETAIL_NEIGHBOR,
  TRACKING_STATE_NOT_DELIVERED,
  TRACKING_STATE_NOT_PICKED_UP,
  TRACKING_STATE_SHADOWED,
  TRACKING_STATE_UNKNOWN
} from '@/store/constants';
import OverviewPickedUpComponent from '@/components/OverviewPickedUpComponent';
import { configGetter } from '@/config/configGetter';
import { getUtmSource } from '@/router/queryParam';
import { supportsPushNotification } from '@/services/pushNotification';
import TippingComponent from '@/components/TippingComponent.vue';
import publicPath from '@/util/publicPath';
import EtaInformationBanner from '@/components/EtaInformationBanner.vue';
import DynamicTourInfoIcon from '@/components/DynamicTourInfoIcon.vue';
import dialogs from '@/components/dialogs/dialogs.js';

export default {
  inheritAttrs: false,
  name: 'OverviewComponent',
  components: {
    DynamicTourInfoIcon,
    EtaInformationBanner,
    LastUpdatedComponent,
    OverviewDeliveredComponent,
    OverviewPickedUpComponent,
    TippingComponent
  },
  data() {
    return {
      postcode: this.$route.params.postcode || '',
      DELIVER_TO_NEIGHBOUR,
      DELIVERY_NOTE,
      DEPOSIT_PERMISSION,
      NOTIFICATION_TYPE_ELIGIBLE,
      NOTIFICATION_TYPE_SET,
      TRACKING_STATE_BLOCKED,
      TRACKING_DELIVERY_STATE_CLOSE,
      TRACKING_STATE_DELIVERED,
      TRACKING_STATE_DETAIL_NEIGHBOR,
      TRACKING_DELIVERY_STATE_FAR,
      TRACKING_DELIVERY_STATE_LAST,
      TRACKING_DELIVERY_STATE_NEAR,
      TRACKING_STATE_NOT_DELIVERED,
      TRACKING_STATE_NOT_PICKED_UP,
      TRACKING_DELIVERY_STATE_UNCLEAR,
      TRACKING_STATE_UNKNOWN,
      TRACKING_STATE_SHADOWED,
      supportsPushNotification,
      tippingClosed: false,
      dialogs
    };
  },
  computed: {
    ...mapState(['trackingData', 'isBelowMobileBreakpoint']),
    ...mapGetters([
      'getTrackingState',
      'getTimeframe',
      'isServiceTypePickup',
      'getThemeId'
    ]),
    timeFrameDesc() {
      const { from, to } = this.getTimeframe;

      return `${moment(from).format(this.$rttMoment.timeFormat)} - ${moment(
        to
      ).format(this.$rttMoment.timeFormat)}`;
    },
    overviewHeader() {
      return this.$t(
        this.isServiceTypePickup
          ? 'overview.details.pickupLastStop.header'
          : 'overview.details.deliveryLastStop.header'
      );
    },
    unclearHeader() {
      return this.$t(
        !this.isServiceTypePickup
          ? 'overview.details.unclear.header'
          : 'overview.details.unclearPickup.header'
      );
    },
    showCustomizationButtons() {
      return !this.trackingData.customizedDelivery;
    }
  },
  methods: {
    ...mapActions(['toggleDialog']),
    publicPath,
    configGetter,
    getUtmSource: () => getUtmSource(this.$router),
    dialogHandler(dialog) {
      this.toggleDialog(dialog);
    },
    hasCustomizedDeliveryPeriod() {
      return !!this.$store.state.deliveryPeriod;
    },
    handleCloseTipping() {
      this.tippingClosed = true;
    },
    reverseStateInfoLayout() {
      return this.$rttI18n.isGlsItSelected || this.$rttI18n.isRoyalMailSelected;
    }
  },
  watch: {
    '$route.params.postcode'(data) {
      this.postcode = data;
    }
  }
};
</script>

<style scoped>
.overview {
  margin-bottom: 10px;
  text-align: center;
}

.overview p {
  color: #000;
}

.overview .header {
  color: #061ab1;
  font-size: 16px;
}

.overview .v-icon {
  font-size: 36px;
  line-height: 48px;
}

.overview hr {
  border: none;
  border-bottom: 1px solid #e6e8f7;
}

button.secondary {
  color: #061ab1 !important;
  background-color: transparent !important;
  border: 1px solid #061ab1 !important;
}

.deliveryPeriodIcon {
  margin-bottom: 3px;
  margin-left: 3px;
  width: 26px;
  /* filter: brightness(0); */ /* https://codepen.io/sosuke/pen/Pjoqqp */
}

.tipping-container {
  margin-bottom: 1em;
}
</style>
