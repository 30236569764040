<template>
  <div class="nps-container">
    <h3>{{ this.$rttI18n.tenantSpecificContent('headerNps') }}</h3>
    <p>
      {{ this.$rttI18n.tenantSpecificContent('bodyNps') }}
    </p>
    <hr />
    <div :class="!isBelowTabletBreakpoint ? 'form-container' : ''">
      <div id="consent-container">
        <v-checkbox
          class="checkbox"
          v-model="NPSConsent"
          @update:model-value="toggleNPSConsent"
          color="info"
          required
        >
        </v-checkbox>
        <div
          class="my-4"
          v-html="this.$rttI18n.tenantSpecificContent('consentDeclarationNps')"
        ></div>
      </div>
      <div id="button-container">
        <v-btn
          :disabled="!NPSConsent"
          data-testid="btn-customizeDeliveryPeriod"
          color="info"
          :block="true"
          class="text-body-1 my-4"
          v-on:click="openNPS"
          >{{ this.$rttI18n.tenantSpecificContent('buttonNps') }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { LOCAL_STORAGE_LOCALE_KEY } from '@/store/constants.js';
import { sendAnalytics } from '@/services/analytics.js';

export default {
  name: 'NPSComponent',
  computed: {
    ...mapState(['NPSConsent', 'isBelowTabletBreakpoint']),
    ...mapGetters(['getTrackingData'])
  },
  methods: {
    ...mapActions(['toggleNPSConsent']),
    openNPS() {
      sendAnalytics(this.getTrackingData, 'clicksStats', 'NPS');
      window.open(this.getQualtricsURL());
    },
    getQualtricsURL() {
      let url = new URL(this.$rttI18n.tenantSpecificContent('buttonNpsLink'));
      const language = localStorage
        .getItem(LOCAL_STORAGE_LOCALE_KEY)
        .split('-')[0]
        .toUpperCase();
      url.searchParams.set(
        'Q_Language',
        `${language !== 'IT' ? 'EN' : language}`
      );
      url.searchParams.set('tp', 'RTT');
      url.searchParams.set('trackingId', this.getTrackingData.trackingId);
      url.searchParams.set('country', 'ITA');
      url.searchParams.set('shippingRole', 'consignee');
      return url;
    }
  }
};
</script>

<style scoped>
.checkbox {
  display: block;
}
div#consent-container {
  display: flex;
  text-align: left;
}
div#button-container {
  button:disabled {
    color: rgb(164 166 182) !important;
    background-color: rgb(226 226 226) !important;
  }
}
div.form-container {
  display: flex;
}
div.nps-container {
  padding-top: 1em;
  text-align: center;
}
hr {
  margin-top: 1.5em;
  margin-bottom: 0.8em;
}
</style>
