<template>
  <component v-if="currentComponent" v-bind:is="currentComponent"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import loadConfigByThemeId from '@/config/themes';
import { markRaw } from 'vue';

export default {
  name: 'ThemeElementSlot',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  data: () => ({
    currentComponent: null
  }),
  computed: {
    ...mapGetters(['getThemeId'])
  },
  methods: {
    async loadComponent() {
      const config = await loadConfigByThemeId(this.getThemeId);
      this.currentComponent = config.slots[this.name]
        ? markRaw(config.slots[this.name])
        : config.slots[this.name];
    }
  },
  watch: {
    async getThemeId() {
      this.loadComponent();
    }
  },
  async created() {
    this.loadComponent();
  }
};
</script>

<style scoped></style>
