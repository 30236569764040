import Vuex from 'vuex';
import { createActions } from './actions';
import getters from './getters';
import mutations from './mutations';
import {
  LOCAL_STORAGE_GDPR_KEY,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT
} from '@/store/constants.js';

export function setupVuex(app, store) {
  app.use(Vuex);
  app.use(store);
}

const state = {
  message: {
    modal: false
  },
  gdprConsent: JSON.parse(localStorage.getItem(LOCAL_STORAGE_GDPR_KEY) || '{}'),
  NPSConsent: false,
  themeId: undefined,
  deliveryPeriod: undefined,
  isPrivate: false,
  isBelowMobileBreakpoint: window.innerWidth < MOBILE_BREAKPOINT,
  isBelowTabletBreakpoint: window.innerWidth < TABLET_BREAKPOINT,
  loadingProcesses: {},
  trackingData: {
    supportedCustomizedDeliveryTypes: [],
    status: {
      remainingStops: 0
    },
    maxStopsToShowDriverLocation: 10
  },
  activeDialog: null,
  dialogPayload: {}
};

export function createStoreAndLinkHttpService(httpService) {
  const store = new Vuex.Store({
    name: 'store',
    state: { ...state },
    actions: createActions(httpService),
    getters,
    mutations,
    strict: import.meta.env.NODE_ENV !== 'production'
  });
  httpService.setStore(store);
  return store;
}
