import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory
} from 'vue-router';
import routes from './routes';

export function setupRouter(jsLibMode) {
  const history = jsLibMode ? createMemoryHistory() : createWebHashHistory();
  return createRouter({
    history,
    routes,
    scrollBehavior() {
      return { left: 0, top: 0 };
    }
  });
}
