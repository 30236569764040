<template>
  <div
    id="lottie-player"
    style="
      margin: 0;
      position: relative;
      overflow: visible;
      pointer-events: none;
    "
  >
    <div class="lottie-player-absolute-container" :style="containerStyle">
      <Vue3Lottie
        :animationLink="publicPath(file)"
        :loop="loop"
        :style="playerStyle"
      />
    </div>
  </div>
</template>
<script>
import { Vue3Lottie } from 'vue3-lottie';
import publicPath from '@/util/publicPath';

export default {
  name: 'LottieAnimation',
  components: {
    Vue3Lottie
  },
  methods: { publicPath },
  props: {
    top: {
      type: Number,
      default: 0
    },
    file: {
      type: String
    },
    containerStyle: {
      type: String,
      default: ''
    },
    playerStyle: {
      type: String,
      default: ''
    },
    loop: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style>
.lottie-player-absolute-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
