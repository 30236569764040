<template>
  <v-row class="v-col-12 pa-0 ma-0 mt-10 content-container">
    <v-col
      cols="12 pa-0"
      v-if="$route.name && $route.name.startsWith('imprint')"
      ><div
        class="collapsable"
        v-html="$rttI18n.tenantSpecificContent('imprint.content')"
    /></v-col>
    <v-col
      cols="12 pa-0"
      v-if="$route.name && $route.name.startsWith('privacy')"
      ><div
        class="collapsable"
        v-html="$rttI18n.tenantSpecificContent('privacy.content')"
    /></v-col>
    <v-col
      cols="12 pa-0"
      v-if="$route.name && $route.name.startsWith('contact')"
      ><div
        class="collapsable"
        v-html="$rttI18n.tenantSpecificContent('contact.content')"
    /></v-col>
  </v-row>
</template>

<script>
export default {
  name: 'LegalContainer'
};
</script>

<style scoped></style>
