export default Object.fromEntries(
  [
    'AT',
    'BE',
    'CA',
    'CZ',
    'DE',
    'ES',
    'FR',
    'GB',
    'HR',
    'HU',
    'IE',
    'IT',
    'NL',
    'PL',
    'RO',
    'RS',
    'SI',
    'SK',
    'UK',
    'US'
  ].map(country => [country, country])
);
