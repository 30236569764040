import { validateLocale } from '@/services/localeValidation.js';

export default {
  install(app) {
    function updateLocale(newLocale) {
      validateLocale(newLocale);
      app.config.globalProperties.$rttI18n.setLocale(newLocale);
      app.config.globalProperties.$rttMoment.setLocale(newLocale);
    }
    app.config.globalProperties.$localePlugin = { updateLocale };
  }
};
