import * as httpConstants from '../httpConstants';

class HttpRequest {
  body = null;

  dispatches = {};

  headers = {};

  method = null;

  path = null;

  pathVariables = {};

  queryParameters = {};

  url = null;

  /**
   * @param {string} path
   * @param {string} method
   */
  constructor(path, method) {
    this.method = method;
    this.path = path;

    this.dispatches[httpConstants.REQUEST_ERROR] = [];
    this.dispatches[httpConstants.REQUEST_FAILURE] = [];
    this.dispatches[httpConstants.REQUEST_INIT] = [];
    this.dispatches[httpConstants.REQUEST_NOTFOUND] = [];
    this.dispatches[httpConstants.REQUEST_SUCCESS] = [];
    this.dispatches[httpConstants.REQUEST_UNAUTHORIZED] = [];
  }

  getDispatches(type) {
    return this.dispatches[type];
  }

  addDispatch(type, dispatchCallback) {
    this.dispatches[type].push(dispatchCallback);
  }

  setBody(body) {
    this.body = body;
  }

  getBody() {
    return this.body;
  }

  getHeader(name, defaultValue) {
    if (Object.prototype.hasOwnProperty.call(this.headers, name)) {
      return this.headers[name];
    }

    return defaultValue || null;
  }

  /**
   * @param {string} name Header name
   * @param {string} value Header value
   */
  setHeader(name, value) {
    this.headers[name] = value;
  }

  /**
   *
   * @returns {{}}
   */
  getHeaders() {
    return this.headers;
  }

  setHeaders(headers) {
    Object.keys(headers).forEach(header => {
      this.setHeader(header, headers[header]);
    });
  }

  // TODO: the handling of pathVariables and queryParams needs to be implemented
  getPathVariable(name, defaultValue) {
    if (Object.prototype.hasOwnProperty.call(this.pathVariables, name)) {
      return this.pathVariables[name];
    }

    return defaultValue !== undefined ? defaultValue : null;
  }

  setPathVariable(name, value) {
    this.pathVariables[name] = value;
  }

  getPathVariables() {
    return this.pathVariables;
  }

  setPathVariables(pathVariables) {
    this.pathVariables = pathVariables;
  }

  getQueryParameter(name, defaultValue) {
    if (Object.prototype.hasOwnProperty.call(this.queryParameters, name)) {
      return this.queryParameters[name];
    }

    return defaultValue !== undefined ? defaultValue : null;
  }

  setQueryParameter(name, value) {
    this.queryParameters[name] = value;
  }

  getQueryParameters() {
    return this.queryParameters;
  }
  // todo ends here

  getMethod() {
    return this.method;
  }

  setMethod(method) {
    this.method = method;
  }

  getPath() {
    return this.path;
  }

  setPath(path) {
    this.path = path;
  }

  getUrl() {
    return this.url;
  }

  setUrl(url) {
    this.url = url;
  }
}

export default HttpRequest;
