import moment from 'moment';
import { computed, reactive } from 'vue';
import tenants from '@/config/tenants.js';
import isoCountryCodes from '@/config/isoCountryCodes.js';
import { LOCAL_STORAGE_TENANT_KEY } from '@/store/constants.js';

function getTimeFormat(region) {
  if (region.toUpperCase() === isoCountryCodes.US) return 'hh:mm A';

  return 'HH:mm';
}

function getDayFormat(region) {
  if (region.toUpperCase() === isoCountryCodes.CA) return 'YYYY-MM-DD';
  if (region.toUpperCase() === isoCountryCodes.US) return 'MM/DD/YYYY';
  if (region.toUpperCase() === isoCountryCodes.UK) return 'DD/MM/YYYY';
  if (region.toUpperCase() === isoCountryCodes.IE) return 'DD/MM/YYYY';

  return 'DD.MM.YYYY';
}

function getTimeAndDayFormat(country) {
  return `${getTimeFormat(country)} - ${getDayFormat(country)}`;
}

export default function createRttMoment({ locale }) {
  const $rttMoment = reactive({
    locale: locale,
    weekStartDay: computed(function getWeekStartDay() {
      try {
        // 2023-05: currently not supported by Firefox
        return new Intl.Locale($rttMoment.locale).weekInfo.firstDay;
      } catch (error) {
        return 1; // fallback 1 = monday
      }
    }),
    country: computed(function (...params) {
      return tenants[localStorage.getItem(LOCAL_STORAGE_TENANT_KEY)]?.country
        .code;
    }),
    region: computed(function () {
      return $rttMoment.locale.split('-')[1] || $rttMoment.country;
    }),
    timeFormat: computed(function () {
      return getTimeFormat($rttMoment.region);
    }),
    dayTimeFormat: computed(function () {
      return getTimeAndDayFormat($rttMoment.region);
    }),
    setLocale: function setLocale(locale) {
      $rttMoment.locale = locale;
      moment.updateLocale(locale, { week: { dow: $rttMoment.weekStartDay } }); // 0 = Sun, 1 = Mon
    },
    weekdays: computed(function () {
      const weekdays = [];
      const firstDay = $rttMoment.weekStartDay;
      for (let i = firstDay; i < firstDay + 7; i++) {
        const date = new Date('Sun Feb 25 2024');
        date.setDate(date.getDate() + (i % 7));

        const weekDay = {
          shortEngName: date.toLocaleDateString('en-us', {
            weekday: 'short'
          }),
          localeName: date.toLocaleDateString($rttMoment.locale.toLowerCase(), {
            weekday: 'long'
          })
        };
        weekdays.push(weekDay);
      }
      return weekdays;
    })
  });

  return {
    get $rttMomentInstance() {
      return $rttMoment;
    },
    install(app) {
      app.config.globalProperties.$moment = moment;
      app.config.globalProperties.$rttMoment = $rttMoment;
    }
  };
}
