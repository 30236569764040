<template>
  <section
    v-if="getTrackingState === this.TRACKING_STATE_DELIVERED"
    class="overviewDelivered"
  >
    <div>
      <img
        alt="Delivery Completed"
        :src="
          publicPath(`/themes/${themeId}/icon_status_delivered_complete.svg`)
        "
        style="width: 60px"
      />
      <p class="header">
        <span class="pa-1 font-weight-bold">{{
          $t('overview.details.stateDelivered')
        }}</span>
      </p>
      <div
        v-if="
          getPrivateTrackingState &&
          trackingData.tenantId !== TENANT_IDS.ROYALMAIL_UK
        "
        class="mb-3"
      >
        <hr class="mb-3" />
        <div v-if="trackingData.status.detail">
          <span class="pa-1 font-weight-bold">{{ $t('generic.where') }}:</span>
          <span class="pa-1"
            >{{
              $t(
                `overview.details.delivered.details.${trackingData.status.detail}`
              )
            }}
          </span>
        </div>
        <div v-if="trackingData.status.name">
          <span class="pa-1 font-weight-bold">{{ $t('generic.name') }}: </span>
          <span class="pa-1">{{ trackingData.status.name }}</span>
        </div>
        <div class="ma-0" v-if="trackingData.status.address">
          <span
            class="pa-1 d-inline-block font-weight-bold"
            style="vertical-align: top"
          >
            {{ $t('generic.address') }}:
          </span>
          <span class="pa-1 d-inline-block">
            {{ trackingData.status.address.name }}
            <br />
            {{ trackingData.status.address.street }}
            {{ trackingData.status.address.number }}
            <br />
            {{ trackingData.status.address.postcode }}
            {{ trackingData.status.address.city }}
          </span>
        </div>
        <div class="ma-0" v-if="trackingData.status.deliveredAt">
          <span class="pa-1 font-weight-bold"> {{ $t('generic.when') }}: </span>
          <span class="pa-1">{{ getDeliveredAtTime }}</span>
        </div>
      </div>
    </div>
    <div>
      <hr />
      <br />
      <p class="font-weight-bold mb-0" v-if="this.$rttI18n.isRoyalMailSelected">
        {{
          $rttI18n.tenantSpecificWithFallback(
            'overview.details.goBackToDetails'
          )
        }}
      </p>
      <p v-if="getTrackingNote()">
        <span class="font-weight-bold" v-html="getTrackingNote()"></span>
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  TRACKING_STATE_DELIVERED,
  TRACKING_STATE_DETAIL_NEIGHBOR,
  TRACKING_STATE_DETAIL_PARCEL_SHOP
} from '@/store/constants';
import publicPath from '@/util/publicPath';
import { configGetter } from '@/config/configGetter.js';
import { TENANT_IDS } from '@/config/tenants.js';

export default {
  name: 'OverviewDeliveredComponent',
  props: {
    postcode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      hasCalledDeliveryDetails: false,
      TRACKING_STATE_DELIVERED,
      TRACKING_STATE_DETAIL_NEIGHBOR,
      TRACKING_STATE_DETAIL_PARCEL_SHOP
    };
  },
  computed: {
    TENANT_IDS() {
      return TENANT_IDS;
    },
    ...mapState(['trackingData', 'themeId']),
    ...mapGetters([
      'getDeliveryStatusDetail',
      'getPrivateTrackingState',
      'getTrackingState'
    ]),
    getDeliveredAtTime() {
      if (this.trackingData.status.deliveredAt) {
        return this.$moment(this.trackingData.status.deliveredAt).format(
          this.$rttMoment.dayTimeFormat
        );
      }
      return null;
    }
  },
  methods: {
    publicPath,
    getTrackingNote() {
      if (
        this.$rttI18n.tenantId &&
        !configGetter(this.$rttI18n.tenantId, this.themeId).disableMoreInfoLink
      ) {
        return this.$rttI18n.tenantSpecificContent('glsTrackingNote', {
          trackingId: this.trackingData.trackingId,
          postcode: this.postcode
        });
      }
      return '';
    }
  }
};
</script>

<style scoped>
.overview p,
.overview div {
  color: #000;
  font-size: 14px;
}

.overview hr {
  border: none;
  border-bottom: 1px solid #e6e8f7;
}
</style>
