import { TENANT_IDS } from '@/config/tenants.js';

export const tenantsForCustomUi = {
  glsAt: TENANT_IDS.GLS_AT,
  glsDe: TENANT_IDS.GLS_DE,
  glsHu: TENANT_IDS.GLS_HU,
  glsIt: TENANT_IDS.GLS_IT,
  royalMailUK: TENANT_IDS.ROYALMAIL_UK,
  royalMailUKTest: TENANT_IDS.ROYALMAIL_UK_TEST
};
