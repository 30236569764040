// class for handling non network error cases (everything with a http status)
export class HttpError extends Error {
  constructor(message, data) {
    super(message); // (1)
    this.name = 'HttpError';
    this.response = data.response;
  }
}

export async function convertJsonFetchPromise(fetchPromise) {
  return fetchPromise
    .then(async response => {
      if (!response.ok)
        throw new HttpError(await response.text(), { response });

      return [null, await response.json()];
    })
    .catch(err => [err, null]);
}
