/** HTTP METHODS */
export const HTTP_METHOD_DELETE = 'DELETE';
export const HTTP_METHOD_GET = 'GET';
export const HTTP_METHOD_PATCH = 'PATCH';
export const HTTP_METHOD_POST = 'POST';
export const HTTP_METHOD_PUT = 'PUT';

/** REQUEST DISPATCH TYPES */
// API Response indicates 401
export const REQUEST_UNAUTHORIZED = 'REQUEST_UNAUTHORIZED';

// hard API communciation errors (network communication failure; no network, 401 etc.)
export const REQUEST_ERROR = 'REQUEST_ERROR';

// Response received errors e.g. 500
export const REQUEST_FAILURE = 'REQUEST_FAILURE';

// dispatched before actual API communication starts
export const REQUEST_INIT = 'REQUEST_INIT';

// successful reception of response
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';

// 404
export const REQUEST_NOTFOUND = 'REQUEST_NOTFOUND';

export const HTTP_STATUS_UNREACHABLE = 0;
